@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Vos styles personnalisés peuvent être ajoutés ici */
html,body{
  overflow-x: hidden;
}
body {
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: MONOSPACE;
    font-size: 18px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.draggable-exercise.dragging {
  opacity: 1;
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: scale(1);
    background-color: white;
  }
  40% {
    transform: scale(1.1);
    background-color: rgb(250, 204, 21);
  }
  60% {
    transform: scale(0.9);
    background-color: white;
  }
}

.bounce-animation {
  animation: bounce 1s ease, bounce 1s ease;
}
.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.lottieContainer {
  position: relative;
  top: -190px;
  margin-bottom: -190px;
  width: 420px;
}
#exercise-correction-modal .lottieContainer {
  top: -100px;
  margin-bottom: -100px;

}
@media screen and (max-width: 768px) {
  .lottieContainer {
    top: -100px;
    margin-bottom: -100px;
  }
}
.lottieContainer path[fill="rgb(255,111,15)"]{
  fill: #FACC15 !important;
}
#animation-container {
  opacity: 0.2;
}
.neo-checkbox {
  appearance: none;
  background-color: #fff;
  border: 2px solid #000;
  min-width: 20px;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
  transition: background-color 0.3s, border-color 0.3s;
}

.neo-checkbox:checked {
  background-color: #000;
}

.neo-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
header {
  background-color: #ffcc66;

}
.diagonal-box {
  width: 520px;
  height: 540px;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  padding-top: 74px;
}
.diagonal-box::aftear {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 600px;
  background-color: #333;
}

.diagonal-box::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  transform: skewX(-45deg);
  transform-origin: top right;
}
.menuItem {
  background-color: #ffcc66;
  height: 38px;
  color: #333;
  position: relative;
  width: 80%;
  margin-bottom: 18px;
  line-height: 38px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 24px;
  padding-left: 34px;
  position: relative;
  left: 0;
  transition: all 0.15s ease-out;
  cursor: pointer;
  text-transform: uppercase;
  display: block;
}
.menuItem:hover {
  left: -30px;
  padding-left: 54px;
 /* background-color: #FACC15;*/

}
.menuItem:nth-child(1) {
  width: 534px;
}
.menuItem:nth-child(2) {
  width: 458px
}
.menuItem:nth-child(3) {
  width: 382px;
}
.menuItem:nth-child(4) {
  width: 306px;
}
.menuItem:nth-child(5) {
  width: 228px;
}
.menuItem:after {
  content: '';
  position: absolute;
  top: 0;
  right: -58px;
  width: 58px;
  height: 100%;
  /*background-color: #ffcc66;*/
  transform: skewX(-45deg);
  transform-origin: top right;
  transition: all 0.25s ease-in-out;
}
.menuItem:hover:after {
 /* background-color: #FACC15;*/
}
@media screen and (max-width: 1475px) {
  .diagonal-box {
    width: 620px;
  }
}
@media screen and (max-width: 1200px) {
  #teacherImg {
    left: calc(50% - 200px);
    bottom: 0px;
  }
  #logoLarge {
    zoom: 0.8;
  }
  #circleLogo {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  #logoLarge {
    zoom: 0.6;
  }
}
@media screen and (max-width: 720px) {
  .diagonal-box:before {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  #logoLarge {
    zoom: 0.4;
  }
}/*
#teacherImg, #logoLarge, #circleLogo {
  position: relative;
  transition: transform 0.1s ease-out;
}*/
@keyframes slideUp {
  from {
    transform: translateY(100%) translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
}

.animate-slide-up {
  animation: slideUp 1s ease-out;
}